import { Accent } from './ComfortEnvironment.styles';
import cityGoodIcon from './assets/city-good-icon.svg?url';
import familyIcon from './assets/family-icon.svg?url';
import growBuildingIcon from './assets/grow-building-icon.svg?url';
import roadsIcon from './assets/roads-icon.svg?url';
import { IIndicatorConfig } from './types';

export const indicatorsConfig: IIndicatorConfig[] = [
  {
    id: 0,
    image: {
      src: familyIcon,
      width: 100,
      height: 83,
    },
    title: (
      <>
        <Accent tag="span">5</Accent>
        {' '}
        млн семей
      </>
    ),
    description: (
      <>
        улучшают жилищные условия
        {' '}
        <br />
        {' '}
        ежегодно
      </>
    ),
  },
  {
    id: 1,
    image: {
      src: growBuildingIcon,
      width: 100,
      height: 83,
    },
    title: (
      <>
        <Accent tag="span">120</Accent>
        {' '}
        млн м
        <sup>2</sup>
      </>
    ),
    description: (
      <>
        увеличение объема жилищного
        {' '}
        <br />
        {' '}
        строительства не менее 120 млн м2 в год
      </>
    ),
  },
  {
    id: 2,
    image: {
      src: cityGoodIcon,
      width: 100,
      height: 75,
    },
    title: (
      <>
        в
        {' '}
        <Accent tag="span">1,5</Accent>
        {' '}
        раза
      </>
    ),
    description: (
      <>
        улучшение качества городской
        {' '}
        <br />
        {' '}
        среды
      </>
    ),
  },
  {
    id: 3,
    image: {
      src: roadsIcon,
      width: 100,
      height: 73,
    },
    title: (
      <>
        <Accent tag="span">85</Accent>
        %
      </>
    ),
    description: (
      <>
        дорожной сети городских агломераций
        {' '}
        <br />
        {' '}
        в нормативном состоянии
      </>
    ),
  },
];
