import Image from 'next/image';

import { Typography } from '@core/UI/Typography';

import * as UI from './ComfortEnvironment.styles';
import { indicatorsConfig } from './config';

const ComfortEnvironment = () => (
  <UI.ComfortEnvironmentContainer>
    <UI.Subtitle>Национальная цель</UI.Subtitle>
    <UI.Title tag="h2">Комфортная и безопасная среда для жизни</UI.Title>
    <UI.Legend tag="p">Показатели, характеризующие достижение национальной цели развития Российской Федерации до 2030 года:</UI.Legend>

    <UI.IndicatorsList>
      {indicatorsConfig.map((indicator) => (
        <UI.Indicator key={indicator.id}>
          <Image alt="" aria-hidden="true" role="presentation" {...indicator.image} />
          <UI.IndicatorWrapper>
            <UI.IndicatorTitle tag="p">{indicator.title}</UI.IndicatorTitle>
            <Typography type="p1">{indicator.description}</Typography>
          </UI.IndicatorWrapper>
        </UI.Indicator>
      ))}
    </UI.IndicatorsList>
  </UI.ComfortEnvironmentContainer>
);

export default ComfortEnvironment;
