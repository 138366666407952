import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

export const ComfortEnvironmentContainer = styled.div`
  position: relative;
  background-color: ${colors.aquaHaze};
  padding: 40px 32px 66px 32px;
`;

export const Subtitle = styled(Typography).attrs({ type: 'p3', weight: 'bold', transform: 'uppercase' })`
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.12em;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 16px;
    letter-spacing: 0px;
  }
`;

export const Title = styled(Typography).attrs({ type: 'h2' })`
  text-align: center;
  margin-bottom: 17px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 40px;
    line-height: 36px;
  }
`;

export const Legend = styled(Typography).attrs({ type: 'p1', weight: 'normal' })`
  text-align: center;
  margin-bottom: 30px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    margin-bottom: 60px;
  }
`;

export const IndicatorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
  width: 100%;

  @media ${BREAKPOINTS.TABLET} {
    width: 650px;
    margin: 0px auto;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 20px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    width: 925px;
  }
`;

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media ${BREAKPOINTS.TABLET} {
    width: calc(50% - 10px);
  }
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  br {
    display: none;

    @media ${BREAKPOINTS.DESKTOP} {
      display: inline-block;
    }
  }
`;

export const IndicatorTitle = styled(Typography).attrs({ color: 'vividTangerine', type: 'p1' })`
  font-size: 20px;
`;

export const Accent = styled(Typography).attrs({ color: 'inherit', weight: 'bold', type: 'p1' })`
  font-size: 48px;
  line-height: 55px;
`;
