import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PagePreloader } from '@eisgs/preloader';

import { IDataProviderProps } from './types';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DataProvider = <T,>(props: IDataProviderProps<T>) => {
  const {
    children,
    selector,
    onMountCallback = () => {},
    onUnmountCallback = () => {},
    isFetchedDataEmpty,
    noDataFallback,
    fetchErrorFallback,
  } = props;

  const { data, meta } = useSelector(selector);
  const { fetchLoading, fetchError, fetchSuccess } = meta;

  const isDataLoadedAndEmpty = () => {
    if (!data || !fetchSuccess) {
      return false;
    }

    return isFetchedDataEmpty ? isFetchedDataEmpty(data) : _isEmpty(data);
  };

  useEffect(() => {
    onMountCallback();

    return () => {
      onUnmountCallback();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (fetchLoading) {
    return <PagePreloader hasDisabledScroll={false} />;
  }

  if (isDataLoadedAndEmpty() && noDataFallback) {
    return <>{noDataFallback}</>;
  }

  if (isDataLoadedAndEmpty()) {
    return <div>No data</div>;
  }

  if (fetchError) {
    if (fetchErrorFallback) {
      return <>{fetchErrorFallback}</>;
    }

    return <div>Data fetching error</div>;
  }

  return children({
    data,
    fetchLoading,
    fetchError,
    fetchSuccess,
  });
};

export default DataProvider;
